.section {
    display: flex;
    justify-content: center;
    align-items: center;
}


.interaction {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.angleInteraction {
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(227, 228, 237, 0.3);
    width: 500px;
    border-radius: 5px;
    border: 1px solid var(--neutral-dark-color);
    animation: slideDown 2s ease-in-out, backgroundHorizontalSlide 8s ease infinite;
    background: linear-gradient(to right, rgba(227, 228, 237, 0.3), var(--secondary-light-color), rgba(227, 228, 237, 0.3));
    background-size: 300% 100%;
}

@keyframes slideDown {
    from {
        transform: translateY(-300px);
        opacity: 0;
    }

    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes backgroundHorizontalSlide {
    0% {
        background-position: right bottom;
    }

    50% {
        background-position: left bottom;
    }

    100% {
        background-position: right bottom;
    }

}

.icon {
    color: var(--neutral-dark-color);
    font-size: 20px;
    cursor: pointer;
}

.icon:hover {
    color: var(--neutral-dark-darker-color);
}

.title {
    padding: 10px;
    margin: 10px;
    color: var(--neutral-dark-darker-color);
    width: 250px;
    text-align: center;
}

.callToAction{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    backdrop-filter: blur(5px);
    color: var(--neutral-dark-darker-color);
    background-color: hsla(27, 49%, 55%, 0.75);
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    animation: rotateAndScale 2s linear, scaleUpDown 2s linear infinite 2s;
}

@keyframes rotateAndScale {

    0% {
        scale: 0;
        /* transform: rotate(0deg); */
        /* transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); */
    }

    50% {
        scale: 0.5;
        /* transform: rotate(180deg); */
        /* transform: rotateX(180deg) rotateY(180deg) rotateZ(180deg); */
    }

    100% {
        scale: 1;
        /* transform: rotate(360deg); */
        /* transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg); */
    }
    
}

@keyframes scaleUpDown {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}


.serviceImages {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100vw;
    overflow-x: scroll;
    scrollbar-width: none;
}

.serviceImages div img {
    width: 100vw;
    height: 95vh;
    object-fit: cover;
}

@media screen and (max-width: 600px) {
    .angleInteraction {
        width: 300px;
    }

    .title {
        font-size: 20px;
        width: 200px;
    }
}