.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--neutral-light-color);
}

.sectionAnimate{
    width: 100%;
    /* background: linear-gradient(130deg,
    var(--neutral-light-color) 45%, var(--primary-light-color) 45% 50%,
    var(--tertiary-light-color) 50% 55%, var(--neutral-light-color) 55%);
    background-size: 300% 100%;
    background-position: left bottom;
    animation: backgroundHorizontalSlide 3s ease; */
}

@keyframes backgroundHorizontalSlide {
    0% {
        background-position: right center;
    }

    100% {
        background-position: left center;
    }
}

.aboutArticles{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    min-height: 80vh;
}

.article {
    display: none;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px;
    color: var(--primary-light-color);
    box-shadow: 0 0 20px 0 var(--secondary-color);
    background-color: var(--neutral-light-lighter-color);
    border-radius: 5px;
}

.articleAnimate{
    display: flex;
}

.articleAnimate:nth-child(odd) {
    animation: slideInFromRight 2s ease;
}

.articleAnimate:nth-child(even) {
    animation: slideInFromLeft 2s ease;
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.articleHeader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: beat 4s infinite;
    margin: 12.5px;
}

@keyframes beat {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.articleIcon {
    width: 35px;
    height: 35px;
    margin: 5px;
}

.articleTitle {
    line-height: 1.5rem;
    margin: 5px;
}

.articleDescription {
    line-height: 1.2rem;
    text-align: justify;
    padding: 12.5px;
    border-top: 2px solid var(--tertiary-color);
    margin: 12.5px;
}