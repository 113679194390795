.section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url(../assets/coolbackgroundsTwo.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 95vh;
}

.section h2 {
    margin: 10px;
    padding: 10px;
    backdrop-filter: blur(5px);
}

.section p {
    backdrop-filter: blur(5px);
}