.main{
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    min-width: 160px;
    overflow-x: hidden;
}

section{
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
}
