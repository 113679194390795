.header_group {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 5;
    animation: slideDown 2s ease-in-out;
}

@keyframes slideDown {
    from {
        transform: translateY(-100px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

.header {
    height: 75px;
    font-size: 15px;
    background: linear-gradient(to top, transparent, var(--primary-light-color), var(--primary-dark-color));
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-width: 160px;
    width: 100vw;
}

.brand {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.logo {
    color: var(--neutral-light-color);
    width: 25px;
    height: 25px;
    margin: 5px;
}

.brand_name {
    color: var(--neutral-light-color);
    display: flex;
    flex-direction: row;
    margin: 5px;
    cursor: default;
}

.horizontal_page_links {
    list-style: none;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 14px;
}

.horizontal_page_links li a {
    color: var(--neutral-light-color);
    text-decoration: none;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
}

.horizontal_enquire_btn {
    background: var(--secondary-light-color);
    cursor: pointer;
    width: 150px;
    height: 32px;
    border-radius: 16px;
    color: var(--neutral-dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.horizontal_enquire_btn:hover {
    background: var(--secondary-color);
}

.phone_icon {
    color: var(--neutral-dark-color);
    width: 15px;
    height: 15px;
}

.compact_enquire_btn {
    background: var(--secondary-light-color);
    cursor: pointer;
    padding: 8.5px;
    border-radius: 20px;
    color: var(--neutral-dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical_nav{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px;
    width: 100vw;
}

.vertical_page_links{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.vertical_page_links li{    
    text-align: center;
    width: 100px;
    border-radius: 5px;
    line-height: 32px;
    margin: 5px;
    backdrop-filter: blur(5px);
    background:linear-gradient(to bottom, transparent, var(--primary-color), transparent);
    cursor: pointer;
}

.vertical_page_links li a{
    text-decoration: none;
}

@media (max-width: 340px) {

    .brand_name_service_one {
        display: none;
    }

    .brand_name_service_two {
        display: none;
    }

    .horizontal_nav {
        display: none;
    }

    .horizontal_page_links {
        display: none;
    }

    .horizontal_enquire_btn {
        display: none;
    }
}

/* styles for screens between 340px and 750px (tablets) */
@media (min-width: 340px) and (max-width: 900px) {

    .brand_name_service_two {
        display: none;
    }

    .horizontal_nav {
        display: none;
    }

    .horizontal_page_links {
        display: none;
    }

    .horizontal_enquire_btn {
        display: none;
    }
}

/* styles for screens larger than 750px (desktops) */
@media (min-width: 900px) {

    .vertical_nav_and_actions {
        display: none;
    }

    .horizontal_page_links {
        display: flex;
    }

    .vertical_nav {
        display: none;
    }

    .vertical_page_links {
        display: none;
    }

    .compact_enquire_btn {
        display: none;
    }
    
}