.section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: var(--neutral-light-color);
    width: 100vw;
    height: 80vh;
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--primary-color);
    border-radius: 5px;
    border: 2px solid var(--neutral-light-lighter-color);
    box-shadow: 0 0 20px 0 var(--tertiary-color);
}

.contactForm h2 {
    color: var(--secondary-light-color);
    margin: 10px;
    padding: 10px;
}   

fieldset {    
    border: none;
    display: flex;
    margin: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contactFormLabels{
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;
    align-items: end;
}

.contactFormLabels label{
    color: var(--secondary-light-color);
    height: 16px;
    padding: 5px;
    margin: 7px;
    margin-right: 0px;
}

.contactFormInputs{
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: left;
}

.contactFormInputs input{
    height: 16px;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}

.contactFormButtons{
    margin-bottom: 25px;
}
.contactFormButtons button{
    height: 32px;
    width: 100px;
    margin: 10px;
    border-radius: 5px;
}
