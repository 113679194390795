.section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: var(--first-accent-color);
    width: 100%;
    min-height: 95vh;
    padding-top: 200px;
    padding-bottom: 50px;
}

.galleryInteractions{
    margin: 10px;
    padding: 10px;
}

.galleryInteractions label{
    margin: 10px;
    padding: 10px;
    height: 32px;
}

.galleryInteractions select{
    margin: 10px;
    width: 200px;
    padding-left: 10px;
    height: 32px;
    border-radius: 5px;
}

.galleryDisplay{
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; */

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px; /* This controls the space between the images */
    justify-content: center;
}

.galleryDisplay img{
    /* margin: 10px;
    max-width: calc(20% - 20px);
    height: auto; */

    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 2px solid var(--neutral-light-lighter-color);
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
}