@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i&display=swap');

:root {
  /* Own suggestions */
  --steel-gray: #4A4A4A;
  --slate-blue: #506C7F;
  --charcoal-black: #333333;
  --copper: #B87333;
  --brushed-silver: #C0C0C0;
  --creamy-white: #F5F5F5;
  --deep-bronze: #804A15;

  /* Consulted suggestions */
  --charcoal-grey: hsl(212, 11%, 23%);
  --charcoal-grey-dark: hsl(212, 11%, 13%);
  --charcoal-grey-light: hsl(212, 11%, 33%);
  --soft-white: 	hsl(0, 0%, 90%);
  --soft-white-dark: 	hsl(0, 0%, 80%);
  --soft-white-light: 	hsl(0, 0%, 100%);
  --steel-blue: hsl(208, 39%, 47%);
  --steel-blue-dark: hsl(208, 39%, 37%);
  --steel-blue-light: hsl(208, 39%, 57%);
  --sage-green: hsl(122, 21%, 68%);
  --sage-green-dark: hsl(122, 21%, 58%);
  --sage-green-light: hsl(122, 21%, 78%);
  --burnished-bronze: 	hsl(27, 49%, 45%);
  --burnished-bronze-dark: 	hsl(27, 49%, 35%);
  --burnished-bronze-light: 	hsl(27, 49%, 55%);
  --vibrant-teal: hsl(175, 58%, 46%);
  --vibrant-teal-dark: hsl(175, 58%, 36%);
  --vibrant-teal-light: hsl(175, 58%, 56%);
  --Roboto-font-family: 'Roboto', sans-serif;
  --Lato-font-family: 'Lato', sans-serif;
  --Merriweather-font-family: 'Merriweather', serif;

  /* Default Theme */
  /*
  Use neutral colors the most 60-70%,
  primary color 20-30%,
  secondary and tertiary color 10-20%,
  accent colors 1-2%
  */
  --neutral-light-color: var(--soft-white);
  --neutral-light-darker-color: var(--soft-white-dark);
  --neutral-light-lighter-color: var(--soft-white-light);
  --neutral-dark-color: var(--charcoal-grey);
  --neutral-dark-darker-color: var(--charcoal-grey-dark);
  --neutral-dark-lighter-color: var(--charcoal-grey-light);
  --primary-color: var(--charcoal-grey);
  --primary-dark-color: var(--charcoal-grey-dark);
  --primary-light-color: var(--charcoal-grey-light);
  --secondary-color: var(--steel-blue);
  --secondary-dark-color: var(--steel-blue-dark);
  --secondary-light-color: var(--steel-blue-light);
  --tertiary-color: var(--burnished-bronze);
  --tertiary-dark-color: var(--burnished-bronze-dark);
  --tertiary-light-color: var(--burnished-bronze-light);
  --first-accent-color: var(--sage-green);
  --first-accent-dark-color: var(--sage-green-dark);
  --first-accent-light-color: var(--sage-green-light);
  --second-accent-color: var(--vibrant-teal);
  --second-accent-dark-color: var(--vibrant-teal-dark);
  --second-accent-light-color: var(--vibrant-teal-light);

  --primary-font-family: var(--Roboto-font-family);
  --secondary-font-family: var(--Lato-font-family);
  --accent-font-family: var(--Merriweather-font-family);
}

.body {
  font-family: var(--primary-font-family);
  line-height: 1rem;
  background-color: var(--neutral-light-color);
}
